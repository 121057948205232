import React, { Fragment, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import ArtItem from '../components/ArtItem'
import axios from 'axios'
import API_URL from '../utilities/APIUrl'

const Gallery = () => {
  const breakpointColumns = {
    default: 4,
    1100: 3,
    700: 2,
  }
  const [hasMore, setHasMore] = useState(true)

  const [artItems, setArtItems] = useState([])

  const fetchMoreArt = useCallback(async () => {
    console.log(artItems)
    const result = await axios.post(`${API_URL}graphql`, {
      query: `{
        posts(first: 10${
          artItems.length
            ? ', after: "' + artItems[artItems.length - 1].cursor + '"'
            : ''
        }){
          edges {
            node {
              id
              slug
              uri
              title
              featuredImage {
                node {
                  sizes
                  srcSet
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      `,
    })
    setArtItems((prev) => {
      return prev.concat(result.data.data.posts.edges)
    })
    if (result.data.data.posts.pageInfo.hasNextPage) {
      setHasMore(result.data.data.posts.pageInfo.hasNextPage)
    }
  }, [artItems])

  useEffect(() => {
    fetchMoreArt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <h2 className="font-bold lg:text-xl mb-2 uppercase text-left">
        Alla konstverk
      </h2>
      {/* <div className="border-1 border-primary-light">
        <p className="text-lg">Filtrera</p>
      </div> */}
      <div className={'flex flex-wrap justify-center -mx-2 mb-4'}>
        <InfiniteScroll
          dataLength={artItems.length} //This is an important field to render the next data
          next={fetchMoreArt}
          hasMore={hasMore}
          loader={<h4>Laddar...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Du har nu sett alla konstverk!</b>
            </p>
          }
        >
          <Masonry
            breakpointCols={breakpointColumns}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {artItems.map((item, index) => (
              <ArtItem key={index} data={item} />
            ))}
          </Masonry>
        </InfiniteScroll>
      </div>
    </Fragment>
  )
}

export default Gallery
